.callToActionRow {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 0;
    background-color: var(--grey-color);
    box-shadow: 0 0 0 100vmax var(--grey-color);
    clip-path: inset(0 -100vmax);
    color: var(--text-color);
    padding: 64px 0 80px 0;

    &__container {
        max-width: 600px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__header {
        text-transform: capitalize;
        text-align: center;
    }

    &__text{
        max-width: 480px;
        text-align: center;
        font-weight: 300;
        font-size: 20px;
        text-transform: capitalize;
    }

    &__button {
        max-width: 500px;
        margin-top: 36px;
        background-color: var(--background-color);
        color: var(--text-color) !important;
        transition: background-color 0.5s ease-out, color 0.5s ease-out;
    }

    &__button:hover{
        color: var(--background-color) !important;
        background-color: var(--text-color) ;
    }
}