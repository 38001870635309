.hero {
    display: flex;
    flex-direction: row;
    column-gap: 100px;
    justify-content: space-between;

    &__textContainer {
        flex: 1 1 0;
        display: flex;
        flex-direction: column;
        max-width: 600px;
    }

    &__header {
        font-size: 64px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 89.6px */
        text-transform: capitalize;
        margin: 0;
    }

    &__slogan {
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-transform: capitalize;
        color: var(--text-light-color);
        margin: 0;
        margin-top: 24px;
    }

    &__text {
        margin-top: 48px;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-transform: capitalize;
    }

    &__callToAction {
        margin-top: 48px;
        transition:
            background-color 0.5s ease-out,
            color 0.5s ease-out;
    }

    &__callToAction:hover {
        color: var(--background-color) !important;
        background-color: var(--text-color);
    }

    &__svgContainer {
        position: relative;
        display: none;
    }

    &__svg {
        position: absolute;
        overflow: hidden;
        z-index: 2;
        color: var(--primary-color);
    }

    &__svg1 {
        top: -300px;
        left: -55px;
    }

    &__svg2 {
        top: -640px;
        left: 390px;
    }

    &__svg3 {
        top: -200px;
        left: 510px;
    }

    &__svg4 {
        top: -70px;
        left: 360px;
    }

    &__svg5 {
        top: -90px;
        left: -50px;
    }

    &__svg6 {
        top: -160px;
        left: -100px;
    }

    &__image {
        display: none;
        flex: 1 0 auto;
        border-radius: 100px 5px;
        flex: 1 0 auto;
        width: 100%;
        height: 100%;
        max-height: 600px;
        object-fit: cover;
        filter: saturate(0%);
    }

    @media only screen and (max-width: 1288px) {
        &__header {
            font-size: 56px;
        }

        & {
            column-gap: 0;
        }

        &__textContainer {
            max-width: unset;
        }
    }

    @media only screen and (max-width: 425px) {
        &__header {
            font-size: 32px;
        }

        &__slogan {
            font-size: 24px;
        }

        &__text {
            font-size: 16px;
        }
    }

    @media only screen and (min-width: 1288px) {
        &__image {
            display: block;
        }

        &__svgContainer {
            display: block;
        }
    }
}