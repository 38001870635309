.contactCards {
    display: flex;
    flex-wrap: nowrap;
    gap: 48px;
    align-items: stretch;
    flex-direction: row;

    &__card {
        flex: 1;
        text-align: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    &__icon {
        width: 128px;
        height: 128px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--secondary-color);
        color: var(--background-color);
        font-size: 64px;
        margin-bottom: 48px;
    }

    &__cardText {
        margin-bottom: 24px;
    }

    &__link {
        color: var(--text-color);
    }
}

@media screen and (max-width: 1024px) {
    .contactCards {
        flex-direction: column !important;

        &__card:nth-child(1) {
            order: 1
        }
    } /* forces two items to a line */
}