.treatments {
    display: flex;
    flex-wrap: wrap;
    gap: 48px;
    align-items: stretch;

    &__card {
        flex: 1;
        text-align: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    @media screen and (max-width: 1024px) {
        &__card {
            flex-basis: 45%;
        } /* forces two items to a line */
    }

    &__icon {
        width: 128px;
        height: 128px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--secondary-color);
        color: var(--background-color);
        font-size: 48px;
    }
}
