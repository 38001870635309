.footer {
    background-color: var(--grey-color);
    box-shadow: 0 0 0 100vmax var(--grey-color);
    clip-path: inset(0 -100vmax);
    display: block;
    margin: 0;
    padding: 48px 0 48px 0;
    font-size: 16px;
    bottom: 0;

    &__title {
        text-transform: uppercase;
        margin: 0 0 12px 0;
        font-weight: 500;
    }

    &__logo{
        height: 64px;
        width: auto;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__address {
        margin-top: 32px;
        margin-right: 32px;

        &Header{
            color: var(--primary-dark-color);
            margin: 0;
            font-weight: bold !important;
            margin-top: 0 !important;
        }

        p {
            font-size: 16px;
            font-weight: 300;
            margin-top: 8px;
        }
    }

    &__links {
        margin-top: 32px;
        margin-right: 32px;

        &Header{
            color: var(--primary-dark-color);
            margin: 0;
            font-weight: bold !important;
            margin-top: 0;
        }

        a {
            font-weight: 300;
            text-decoration: none;
            color: var(--text-color);
            display: block;
            margin-top: 8px;
        }

        a:hover {
            text-decoration: underline;
            transition: cubic-bezier(0.165, 0.84, 0.44, 1);
        }
    }

    &__contact {
        margin-top: 32px;
        margin-right: 32px;

        &Header{
            color: var(--primary-dark-color);
            margin: 0;
            font-weight: bold !important;
            margin-top: 0;
        }

        a {
            font-weight: 300;
            text-decoration: none;
            color: var(--text-color);
            display: block;
            margin-top: 8px;
            cursor: pointer;
        }

        a:hover {
            text-decoration: underline;
            transition: cubic-bezier(0.165, 0.84, 0.44, 1);
        }
    }

    hr {
        margin-top: 32px;
        border: 1px solid black;
        margin-bottom: 0;
    }

    &__footNote {
        margin-top: 32px;
        font-weight: 300;
        font-size: 16px;
    }
}
