.app {
  margin: auto;
  max-width: 75rem;
  padding-left: 32px;
  padding-right: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  box-sizing: border-box;

  &__main {
    flex: 1;
  }
}