.button {
    width: 100%;
    display: flex;
    justify-content: center;
    border: none;
    border-radius: 100px;
    padding-top: 16px;
    padding-bottom: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 1.28px;
    text-transform: uppercase;

    &:hover {
        cursor: pointer;
    }

    &__filled {
        color: var(--background-color);
        background-color: var(--primary-color);
    }

    &__outlined {
        border: 2px solid var(--primary-color);
        color: var(--primary-color);
        background-color: transparent;
        transition: background-color 0.5s ease-out, color 0.5s ease-out;
    }

    &__outlined:hover {
        color: var(--background-color) ;
        background-color: var(--primary-color) ;
    }
}