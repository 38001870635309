.aboutMeCard {
    &__container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 40px;
        flex-wrap: wrap;
    }

    @media only screen and (max-device-width: 756px) {
        & {
            margin-top: 150px;
        }

        &__container {
            flex-direction: column;
            align-items: center;
            display: flex;
            margin-top: -150px;
        }

        &__image {
            max-width: 200px !important;
        }
    }

    &__image {
        flex: 0.45;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 100%;
            max-height: 100%;
            min-width: 200px;
            border-radius: 100%;
        }
    }

    &__textContainer {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    &__header {
        text-transform: capitalize;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0px;
    }

    &__text {
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        margin: 18px 0 0 0;
    }

    &__button {
        margin-top: 32px;
    }
}
