:root {
  --text-color: rgba(5, 3, 22, 0.9);
  --text-light-color: #050316a8;
  --background-color: #fbfbfe;
  --primary-color: #E9989A;
  --primary-dark-color: #D08183;
  --secondary-color: #656263;
  --grey-color: #D9D9D9;
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

#root {
  height: 100%;
}

body {
  color: var(--text-color);
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  height: 100%;
  font-weight: 300;
  line-height: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

main {
  display: flex;
  flex-direction: column;
  gap: 128px;
}

p {
  margin-bottom: 0;
  margin-top: 0;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-ExtraBoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}