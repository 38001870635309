.nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding-top: 16px;
    padding-bottom: 32px;
    margin-bottom: 64px;
    margin: 1rem;
    z-index: 999;


    &__logo {
        color: var(--text-color);
        transition: color 0.4s;
        width: auto;
        height: 64px;
        margin-bottom: -20px;
    }

    &__toggle,
    &__close {
        font-size: 1.5rem;
        color: var(--text-color);
        cursor: pointer;
    }

    &__list {
        display: flex;
        flex-direction: column;
        row-gap: 2.5rem;
        list-style: none;
   
    }

    &__list__web {
        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-left: 32px;

        li {
            margin-left: 32px;

        }
    }

    &__link {
        color: var(--text-color);
        font-weight: 400;
        transition: color 0.4s;
        text-decoration: none;
    }

    &__close {
        position: absolute;
        top: 1rem;
        right: 1.5rem;
    }

    .show-menu {
        right: 0;
    }
}

.active {
    text-decoration: underline var(--primary-color) !important;
    text-underline-offset: 8px !important;
    text-decoration-thickness: 1px !important;
}


@media screen and (max-width: 1150px) {
    .nav__menu {
        position: fixed;
        top: 0;
        right: -100%;
        background-color: rgba(255,255,255, 0.8);
        backdrop-filter: blur(16px);
        -webkit-backdrop-filter: blur(16px);
        width: 80%;
        height: 100%;
        padding: 6rem 3rem 0;
        transition: right 0.4s;
    }
}

@media screen and (min-width: 1150px) {
    .nav {
        height: calc(var(--header-height) + 1rem);
    }

    .nav__toggle,
    .nav__close {
        display: none;
    }

    .nav__list {
        flex-direction: row;
        column-gap: 2.5rem;
    }
}